<template>
    <div class="address">
        <van-address-list
            class="my-address-list"
            :list="list"
            @edit="onEdit"
        />

        <van-popup v-model="showInfo" class="my-popup" position="bottom" round>
            <van-cell-group class="my-ground" :border="false">
                <van-field v-model="form.userName" label="姓名" placeholder="请输入收件姓名" />
                <van-field v-model="form.mobile" label="手机号" @change="checkMobile" :error-message="showErr ? '手机号格式错误':''" type="number" placeholder="请输入手机号" />
                <van-field v-model="form.area" label="地区" placeholder="请选择地区" @click="regionPopup.show = true" readonly right-icon="arrow"/>
                <van-field v-model="form.address" label="详细地址" type="textarea" placeholder="请输入街道门牌、楼层房间号等信息" />
            </van-cell-group>
            <my-btn :btnStr="'提交'" @onSubmit="onSubmit"></my-btn>
        </van-popup>

        <van-popup v-model="regionPopup.show" round position="bottom">
            <van-area ref="area" @cancel="regionPopup.show = false" title="选择省市区"
                    :columns-placeholder="['请选择', '请选择', '请选择']"
                    :area-list="regionPopup.areaList" :columns-num="3" @confirm="regionCheck" @change="areaChange"/>
        </van-popup>




    </div>

</template>

<script>
export default {
    data() {
        return {
            list: [
                {
                    id: "1",
                    name: "张三",
                    tel: "13000000000",
                    area: '浙江省-杭州市-西湖区',
                    detailAddress: '文三路 138 号东方通信大厦 7 楼 501 室',
                    address: "浙江省杭州市西湖区文三路 138 号东方通信大厦 7 楼 501 室",
                },
            ],
            form: {
                userName: '',
                mobile: '',
                area: '',
                address: '',
                checked: false,
            },
            regionPopup: {
                show: false,
                loadedParent: {},   // 已经加载过数据的省和市
                areaList: {
                    province_list: {},
                    city_list: {},
                    county_list: {},
                },
                checkArea: [],
            },
            showInfo: false,
            checked: false,
            showErr: false
        };
    },
    methods: {
        //提交完后清除数据
        clearForm() {
            for(let i in this.form) {
                if(i === 'checked'){
                    this.form[i] = false
                    this.checked = false
                }else{
                    this.form[i] = ''
                }
            } 
        },
        //校验手机号
        checkMobile() {
            this.showErr = !(/^[1][3,5,7,8][0-9]\d{8}$/.test(this.form.mobile))
        },
        //修改地址
        onEdit(item) {
            //console.log(item)
            this.showInfo = true
            this.form.userName = item.name
            this.form.mobile = item.tel
            this.form.area = item.area
            this.form.address = item.detailAddress
        },
        regionCheck() {

        },
        areaChange() {

        },
        onSubmit() {

        }
    },
    mounted() {
        this.list.forEach(ele=>{
            if(ele.isDefault){
                this.chosenAddressId = ele.id
            }
        })
    }
};
</script>

<style lang="scss">
@import "~@/assets/css/var";
    .address {
        .my-address-list{
            .van-address-item{
                box-shadow: 0 0 6px 3px $boxShadow;
            }
            .van-radio__icon{
                display: none;
            }
            .van-radio__icon--checked{
                .van-icon {
                    background-color: $main;
                    border: 0;
                }
            }
            .van-address-list__bottom{
                display: none;
                .van-button{
                    border: 0;
                    background-color: $main;
                }
            }
        }
        .my-popup {
            height: 35vh;
            overflow: scroll;
            padding-bottom: 60px;
            .van-switch--on{
                background-color: $main;
            }
            .my-ground{
                margin-top: 10px;
            }
        }
        
    }
</style>